import React, {useEffect} from "react";
import { connect } from "react-redux";

import {addCurrentPage} from "../redux/utils/utils.actions";
import ContatoBottomMain from "../components/contato-bottom-main.component";

const ContatoPage = (props) => {

    useEffect(() => {
        scrollToTop()
        props.addCurrentPage("contato");

    }, []);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }

    return(
        <>
            <ContatoBottomMain type="contato"/>
        </>
    )

}

const mapDispatchToProps = dispatch => ({
    addCurrentPage: utils => dispatch(addCurrentPage(utils))
})

export default connect(null, mapDispatchToProps)(ContatoPage);
