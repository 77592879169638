import React, {useState} from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import bgContato from '../assets/imgs/bg-contato-home.jpg';
import detalheVerde from '../assets/imgs/detalhe-verde-inverted-bottom-2.png';
import mapaContato from '../assets/imgs/map-home.jpg';

import { WEB_URLS } from "../utils/configs";


const ContatoBottomMain = (props) => {

    let INITIAL_FORM = {
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
    }

    const [enviando, setEnviando] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [erro, setErro] = useState(false);
    const [dataForm, setDataForm] = useState(INITIAL_FORM);

    const handleSubmit = async (e) => {

        e.preventDefault();

        var data = JSON.stringify({
            "tr": "send-email",
            "nome": dataForm.nome,
            "email": dataForm.email,
            "telefone": dataForm.telefone,
            "mensagem": dataForm.mensagem
        });

        var axiosCreate = axios.create(
            {
                validateStatus: function (status) {
                    return status >= 200;
                }
            }
        )

        var config = {
            method: 'post',
            url: WEB_URLS.urlApi + 'send-email',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        try{
            setEnviando(true);
            let data = await axiosCreate(config).then(({data}) => data)

            if(data.success)
            {
                setSucesso(true)
                setErro(false);
            }
            else
            {
                setSucesso(false);
                setErro(true);
            }

            setEnviando(false);
            return data
        }
        catch (err) {
            setEnviando(false);
            setSucesso(false);
            setErro(true);
        }



    }

    const handleOnChange = event => {
        const { name, value } = event.target;
        setDataForm({...dataForm,[name]: value});
    }

    return(
        <section
            className={`s-contato ${ props.type === "contato" ? 'primeira-ses contato' : '' }`}
            style={{ background: `url(${bgContato}) center / cover `, backgroundRepeat: "no-repeat" }}
        >
            <div className="top-detail"
                 style={{ background: `url(${detalheVerde}) center`}}
            />
            <div className={`container-title title-colecao ${ props.type === "contato" ? 'contato' : '' }`}>
                <div className="box-title">
                    <h2>Fale Conosco</h2>
                </div>
            </div>
            <div className="container container-contato-home">
                <div className="row">

                    {/*<div className="row animated animate__fadeInLeft" style={{animationDuration: '0.2s'}}><h4 style={{color: 'white'}}>ME ANIMA</h4></div>*/}


                    <div  className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 animated animate__fadeInLeft" style={{animationDuration: '0.5s'}}>
                        <h2>Nossos Telefones:</h2>
                        <div className="d-flex justify-content-start align-items-center"
                             style={{ marginTop: '12px', marginBottom: '12px' }}
                        >
                            <div className="home-icon-contato"><FontAwesomeIcon icon={faMobileAlt} /></div>
                            <div className="home-phones-contato">
                                <h3>
                                    <b>(11) 93149-6208 (Rafael)</b><br/>
                                    <b>(11) 99621-3019 (Fábio)</b><br/>
                                </h3>
                            </div>
                        </div>
                        <h2>E-mail:</h2>
                        <p className="p-bold">
                            <b>contato@jaquetaarizona.com.br</b>
                        </p>
                        <h2>
                            Endereço:&nbsp;<span>&nbsp; &nbsp;(clique para ampliar)</span>
                        </h2>
                        <a
                            href="https://goo.gl/maps/xuifbPD2BZQftwnd7" target="_blank">
                            <img className="img-fluid" src={mapaContato}/>
                        </a>
                        <p className="p-nobold">
                            R. Guerra de Águiar, 188 - Jardim Nossa Sra. do Carmo<br/>
                            São Paulo - SP - Brasil - CEP:08275-260<br/>
                        </p>

                    </div>

                    <div className="col animated animate__fadeInLeft" style={{animationDuration: '0.5s'}}>
                        <h2>Envie Sua Mensagem:</h2>
                        { sucesso &&  <h2>Mensagem enviada com sucesso!</h2> }
                        { erro && <h2>Os campos são obrigatórios.</h2> }
                        <form id="frm-contato" className="contato-form" onSubmit={handleSubmit}>
                            <div className="input-group" style={{marginBottom: '16px'}}>
                                <input className="form-control"
                                       type="text"
                                       name="nome"
                                       placeholder="Seu nome"
                                       onChange={handleOnChange}
                                />
                            </div>
                            <div className="input-group" style={{marginBottom: '16px'}}>
                                <input className="form-control"
                                       type="email"
                                       name="email"
                                       placeholder="Seu e-mail"
                                       onChange={handleOnChange}
                                />
                            </div>
                            <div className="input-group" style={{marginBottom: '16px'}}>
                                <input className="form-control"
                                       type="text"
                                       name="telefone"
                                       placeholder="Seu Telefone"
                                       onChange={handleOnChange}
                                />
                            </div>
                            <div className="input-group" style={{marginBottom: '16px'}}>
                                <textarea className="form-control"
                                          placeholder="Sua mensagem"
                                          name="mensagem"
                                          onChange={handleOnChange}
                                />
                            </div>
                            <div className="div-hold-button-contato">
                                <button
                                    className="btn"
                                    id="btn-enviar"
                                    type="submit"
                                    disabled={enviando}
                                >
                                    <span>{ enviando ? "Enviando..." : "Enviar" }</span>

                                </button>
                            </div>
                            <div style={{marginTop: '8px'}}/>
                        </form>
                    </div>



                </div>
            </div>
        </section>
    );

}

export default ContatoBottomMain;